<template>
  <div v-if="!bankAccount"></div>
  <b-modal v-else id="modal-transfer" :title="$t('bank_accounts.transfer')" hide-footer>
    <b-row class="mb-3">
      <b-col lg="3" sm="4">
        {{ $t('bank_accounts.from_account') }}
      </b-col>
      <b-col lg="9" sm="8">
        <BankIcon
            v-b-tooltip.hover
            :bank-code="bankAccount.bankCode"
            :title="$displayBankName(bankAccount.bankCode)"
        />
        {{ bankAccount.bankAccountName }}
        <span class="mx-2">-</span>
        <span>{{ bankAccount.bankAccountNumber }}</span>
      </b-col>
    </b-row>

    <ValidationObserver ref="bankAccountForm" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
            v-slot="{ errors }"
            :name="$t('bank_accounts.bank')"
            rules="required"
        >
          <b-form-group
              :invalid-feedback="errors[0]"
              :label="`${$t('bank_accounts.to')} *`"
              label-cols-lg="3"
              label-cols-sm="4"
              label-for="bank_code"
          >
            <b-form-select
                id="bank_code"
                v-model="form.toBankCode"
                :disabled="isLoading"
                :state="errors[0] ? false : null"
            >
              <template #first>
                <b-form-select-option disabled value="">
                  {{ `-- ${$t('fields.click_select')} --` }}
                </b-form-select-option>
              </template>
              <b-form-select-option
                  v-for="bank in banks"
                  :key="bank.bank_code"
                  :value="bank.bank_code"
              >
                {{ bank.bank_name_th }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
            v-slot="{ errors }"
            :rules="{
            required: true,
            min: 10,
            regex: /^[0-9]+$/,
          }"
            name="account_no"
        >
          <b-form-group
              :invalid-feedback="errors[0]"
              :label="`${$t('bank_accounts.account_no')} *`"
              label-cols-lg="3"
              label-cols-sm="4"
              label-for="bank_number"
          >
            <b-form-input
                id="bank_number"
                v-model="form.toBankAccountNumber"
                v-mask="'############'"
                :aria-invalid="false"
                :disabled="isLoading"
                :state="errors[0] ? false : null"
                aria-describedby="input-live-feedback"
                inputmode="number"
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
            v-slot="{ errors }"
            :name="$t('fields.amount')"
            rules="required|min_value:1"
        >
          <b-form-group
              :invalid-feedback="errors[0]"
              :label="`${$t('fields.amount')} *`"
              label-cols-lg="3"
              label-cols-sm="12"
              label-for="amount"
          >
            <b-form-input
                id="amount"
                v-model="form.amount"
                :disabled="isLoading"
                :state="errors[0] ? false : null"
                inputmode="number"
                type="number"
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>

        <hr>
        <div class="text-right">
          <b-button :disabled="isLoading" class="mr-2" variant="secondary" @click.prevent="$bvModal.hide('modal-transfer')">
            <i class="uil uil-times" />
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button :disabled="isLoading" type="submit" variant="success">
            <b-spinner v-if="isLoading" small />
            <i v-else class="uil uil-check" />
            {{ $t('buttons.submit') }}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import {banks} from '@utils/data-mock';
import {mapActions} from 'vuex';
import {BankCodeEnum} from '@src/constants/bank';

export default Vue.extend({
  name: 'TransferModal',
  props: {
    bankAccount: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      form: {
        toBankAccountNumber: '',
        toBankCode: null,
        amount: null
      }
    }
  },
  computed: {
    banks() {
      return banks.filter((bank) => process.env.VUE_APP_IS_LA === 'true' || bank.bank_code !== BankCodeEnum.BCEL)
    }
  },
  beforeMount() {
    this.form = {
      toBankAccountNumber: '',
      toBankCode: null,
      amount: null
    }
  },
  methods: {
    ...mapActions(['transferFromBankAccount', 'fetchBankAccounts']),
    async onSubmit() {
      this.isLoading = true
      const isSuccess = await this.transferFromBankAccount({
        id: this.bankAccount.id,
        payload: this.form
      })
      this.isLoading = false

      if(isSuccess) {
        this.fetchBankAccounts()
        this.$bvModal.hide('modal-transfer')
      }
    }
  }
})
</script>

<style scoped>

</style>